import React from 'react';

function RecipeList({ recipes, getItemPrice, deleteRecipe }) {
  // Function to format large numbers
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'm'; // Format as millions
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k'; // Format as thousands
    }
    return num.toFixed(2); // For smaller numbers
  };

  const calculateTotalPrice = (items, type) => {
    return items.reduce((total, item) => {
      const itemPrice = getItemPrice(item.product_id, type);
      return total + (itemPrice * item.quantity);
    }, 0);
  };

  const calculateBenefit = (totalInputPrice, totalOutputPrice) => {
    return totalOutputPrice - totalInputPrice;
  };

  const calculateBenefitPerMinute = (benefit, duration) => {
    return duration > 0 ? benefit / duration : 0;
  };

  const formatDuration = (minutes) => {
    const days = Math.floor(minutes / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const mins = minutes % 60;

    let formattedDuration = "";
    if (days > 0) formattedDuration += `${days}d `;
    if (hours > 0) formattedDuration += `${hours}h `;
    if (mins > 0) formattedDuration += `${mins}m`;

    return formattedDuration.trim();
  };

  // Pre-calculate Benefit per Minute for sorting
  const sortedRecipes = [...recipes].map((recipe) => {
    const totalInputPrice = calculateTotalPrice(recipe.inputItems, 'input'); // Pass 'input' to get buyPrice
    const totalOutputPrice = getItemPrice(recipe.outputItem.id, 'output') * recipe.outputItem.quantity; // Pass 'output' to get sellPrice
    
    const benefit = calculateBenefit(totalInputPrice, totalOutputPrice);
    const benefitPerMinute = calculateBenefitPerMinute(benefit, recipe.duration);

    return {
      ...recipe,
      totalInputPrice,
      totalOutputPrice,
      benefit,
      benefitPerMinute,
    };
  }).sort((a, b) => b.benefitPerMinute - a.benefitPerMinute);  // Sort by Benefit per Minute (DESC)

  return (
    <div className="recipe-list-container">
      <h3>Saved Recipes</h3>
      {sortedRecipes.length === 0 ? (
        <p>No recipes saved.</p>
      ) : (
        <table className="recipe-list-table">
          <thead>
            <tr>
              <th>Input Items</th>
              <th>Total Price (Input)</th>
              <th>Output Item</th>
              <th>Total Price (Output)</th>
              <th>Benefit</th>
              <th>Benefit per Minute</th>
              <th>Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedRecipes.map((recipe, index) => (
              <tr key={index}>
                <td>{recipe.inputItems.map(item => `${item.product_id} (x${item.quantity})`).join(', ')}</td>
                <td>{formatNumber(recipe.totalInputPrice)}</td>
                <td>{recipe.outputItem.id ? `${recipe.outputItem.id} (x${recipe.outputItem.quantity})` : 'None'}</td>
                <td>{formatNumber(recipe.totalOutputPrice)}</td>
                <td style={{ color: recipe.benefit < 0 ? 'red' : 'green' }}>
                  {formatNumber(recipe.benefit)}
                </td>
                <td>{formatNumber(recipe.benefitPerMinute)}</td>
                <td>{formatDuration(recipe.duration)}</td>
                <td>
                  <button 
                    onClick={() => deleteRecipe(index)} 
                    style={{
                      backgroundColor: 'red', 
                      color: 'white', 
                      border: 'none', 
                      padding: '5px 10px', 
                      cursor: 'pointer', 
                      borderRadius: '5px'
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default RecipeList;
