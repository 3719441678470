import React, { useState } from 'react';

function RecipeCreation({ inputItems, setInputItems, recipeDuration, setRecipeDuration, outputItem, setOutputItem, saveRecipe }) {
  // Convert duration string in format XdYhZm to total minutes
  const parseDuration = (duration) => {
    const regex = /(\d+d)?(\d+h)?(\d+m)?/;
    const matches = duration.match(regex);

    if (!matches) {
      alert('Invalid duration format. Please use XdYhZm format.');
      return null;
    }

    let days = 0, hours = 0, minutes = 0;

    if (matches[1]) days = parseInt(matches[1].replace('d', ''));
    if (matches[2]) hours = parseInt(matches[2].replace('h', ''));
    if (matches[3]) minutes = parseInt(matches[3].replace('m', ''));

    return days * 1440 + hours * 60 + minutes;
  };

  // Handle recipe creation
  const handleCreateRecipe = () => {
    const durationInMinutes = parseDuration(recipeDuration);

    // Ensure valid input items, duration, and output item
    if (inputItems.length > 0 && durationInMinutes && outputItem.id) {
      const newRecipe = {
        inputItems,
        duration: durationInMinutes, // Store duration in minutes
        outputItem,
      };
      console.log('Reforge Recipe Created:', newRecipe);

      // Save the recipe using the passed saveRecipe function
      saveRecipe(newRecipe);

      // Reset the fields after creating the recipe
      setInputItems([]);
      setRecipeDuration('');
      setOutputItem({ id: '', quantity: 1 });
    } else {
      alert('Please provide at least one input item, a valid duration, and an output item.');
    }
  };

  return (
    <div className="recipe-creation">
      {/* Recipe Duration */}
      <div>
        <input
          type="text"
          placeholder="Enter Duration (e.g., 1d12h42m)"
          value={recipeDuration}
          onChange={(e) => setRecipeDuration(e.target.value)}
        />
      </div>

      {/* Button to Create Recipe */}
      <button onClick={handleCreateRecipe}>Create Reforge Recipe</button>

      {/* Display Current Recipe */}
      <div>
        <h4>Current Recipe:</h4>
        <p><strong>Duration:</strong> {recipeDuration}</p>
        <p><strong>Input Items:</strong> {inputItems.map(item => `${item.product_id} (x${item.quantity})`).join(', ')}</p>
        <p><strong>Output Item:</strong> {outputItem.id ? `${outputItem.id} (x${outputItem.quantity})` : 'None'}</p>
      </div>
    </div>
  );
}

export default RecipeCreation;
