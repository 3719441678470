import React, { useState, useEffect } from 'react';
import SearchBar from './components/SearchBar';
import RecipeCreation from './components/RecipeCreation';
import RecipeList from './components/RecipeList';
import { fetchBazaarData } from './api/hypixelApi'; // Import the fetchBazaarData function
import './App.css';

function App() {
  const [inputItems, setInputItems] = useState([]);
  const [recipeDuration, setRecipeDuration] = useState('');
  const [outputItem, setOutputItem] = useState({ id: '', quantity: 1 });
  const [savedRecipes, setSavedRecipes] = useState([]);
  const [itemPrices, setItemPrices] = useState({});  // Store prices here

  // Fetch Bazaar data when the app loads
  useEffect(() => {
    const storedRecipes = localStorage.getItem('recipes');
    if (storedRecipes) {
      try {
        const parsedRecipes = JSON.parse(storedRecipes);
        setSavedRecipes(parsedRecipes);
      } catch (error) {
        console.error("Error parsing saved recipes from localStorage:", error);
      }
    }

    // Fetch and update Bazaar data
    const fetchPrices = async () => {
      const prices = await fetchBazaarData();
      if (prices) {
        const formattedPrices = {};
        
        // Iterate over all products
      Object.keys(prices).forEach((productId) => {
        const product = prices[productId];
        //console.log(product)
        // Check if 'sell_summary' exists and get the most recent sell price
        if (product.sell_summary && product.sell_summary.length > 0) {
          // Grab the most recent sell price (first entry in sell_summary)
          console.log(product)
          const mostRecentSell = product.sell_summary[0]; 
          const mostRecentBuy = product.buy_summary && product.buy_summary.length > 0
              ? product.buy_summary[0]
              : null;  // Fallback if no buy summary is found

          // Using the most recent sell price
          formattedPrices[productId] = {
            buyPrice: mostRecentBuy  ? mostRecentBuy.pricePerUnit : 0,  // Using buyPrice for input items
            sellPrice: mostRecentSell.pricePerUnit || 0 // Using the most recent sell price
          };
        }
      });
    
        setItemPrices(formattedPrices); // Store the prices in the state
      } else {
        console.error("Failed to fetch Bazaar data.");
      }
    };

    fetchPrices(); // Call the function to fetch prices on load
  }, []);

  // Define the getItemPrice function within the component to access itemPrices state
  const getItemPrice = (productId, type = 'input') => {
    const priceData = itemPrices[productId];
    if (priceData) {
      console.log(productId,priceData);
      // For input items, use buyPrice; for output items, use sellPrice
      return type === 'input' ? priceData.buyPrice : priceData.sellPrice;
    }
    return 0; // Return 0 if the price is not found
  };

  const saveRecipe = (newRecipe) => {
    // Add the new recipe to the state
    setSavedRecipes((prevRecipes) => {
      const updatedRecipes = [...prevRecipes, newRecipe];
      
      // Save the updated recipes list to localStorage
      try {
        localStorage.setItem('recipes', JSON.stringify(updatedRecipes)); // Save immediately
      } catch (error) {
        console.error("Error saving recipes to localStorage:", error);
      }
  
      return updatedRecipes; // Return the updated recipes for the state
    });
  };

  const deleteRecipe = (index) => {
    // Remove the recipe at the specified index
    const updatedRecipes = savedRecipes.filter((_, i) => i !== index);
    
    // Update the state with the new list of recipes
    setSavedRecipes(updatedRecipes);
    
    // Save the updated list to localStorage
    try {
      localStorage.setItem('recipes', JSON.stringify(updatedRecipes)); // Save immediately
    } catch (error) {
      console.error("Error saving updated recipes to localStorage:", error);
    }
  };

  return (
    <div className="App">
      <div className="left-panel">
        <SearchBar 
          inputItems={inputItems} 
          setInputItems={setInputItems} 
          recipeDuration={recipeDuration} 
          setRecipeDuration={setRecipeDuration} 
          outputItem={outputItem} 
          setOutputItem={setOutputItem} 
        />
        <RecipeCreation 
          inputItems={inputItems} 
          setInputItems={setInputItems} 
          recipeDuration={recipeDuration} 
          setRecipeDuration={setRecipeDuration} 
          outputItem={outputItem} 
          setOutputItem={setOutputItem} 
          saveRecipe={saveRecipe}  // Pass saveRecipe function as a prop
        />
      </div>
      <div className="right-panel">
        <RecipeList 
          recipes={savedRecipes} 
          getItemPrice={getItemPrice} 
          deleteRecipe={deleteRecipe} // Pass the deleteRecipe function as a prop
        />
      </div>
    </div>
  );
}

export default App;
