// SearchBar.js
import React, { useState, useEffect } from 'react';
import { fetchBazaarData } from '../api/hypixelApi';

function SearchBar({ setInputItems, setOutputItem }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Fetch Bazaar data on component mount
  useEffect(() => {
    async function fetchData() {
      const data = await fetchBazaarData();
      if (data) {
        setProducts(data);
      }
    }
    fetchData();
  }, []);

  // Handle search input changes
  const handleInputChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term && products) {
      const filtered = Object.keys(products)
        .filter((key) => key.toLowerCase().includes(term))
        .map((key) => products[key])
        .slice(0, 5); // Limit to 5 results
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  };

  // Add an item to the recipe (input items)
  const addToRecipe = (product) => {
    const quantity = prompt(`Enter quantity for ${product.product_id}:`, 1);
    if (quantity && !isNaN(quantity) && quantity > 0) {
      setInputItems((prev) => [...prev, { product_id: product.product_id, quantity: parseInt(quantity) }]);
    } else {
      alert('Please enter a valid quantity.');
    }
  };

  // Set the selected output item and its quantity
  const setAsOutputItem = (product) => {
    const quantity = prompt(`Enter quantity for output item ${product.product_id}:`, 1);
    if (quantity && !isNaN(quantity) && quantity > 0) {
      setOutputItem({ id: product.product_id, quantity: parseInt(quantity) });
    } else {
      alert('Please enter a valid quantity.');
    }
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search for an item..."
        value={searchTerm}
        onChange={handleInputChange}
      />
      {filteredProducts.length > 0 ? (
        <ul className="product-list">
          {filteredProducts.map((product, index) => (
            <li key={index} className="product-item">
              <div className="product-name">{product.product_id}</div>
              <div className="product-prices">
                <span>
                  <strong>Buy Price:</strong> {product.quick_status.buyPrice} coins
                </span>
                <span>
                  <strong>Sell Price:</strong> {product.quick_status.sellPrice} coins
                </span>
              </div>
              <button onClick={() => addToRecipe(product)}>Add to Recipe</button>
              <button onClick={() => setAsOutputItem(product)}>Set as Output Item</button>
            </li>
          ))}
        </ul>
      ) : searchTerm ? (
        <p>No matching items found.</p>
      ) : (
        <p>Enter an item name to see prices.</p>
      )}
    </div>
  );
}

export default SearchBar;
