export async function fetchBazaarData() {
    const apiKey = 'f17af7db-420f-406e-9dea-6544198ddf10'; // Replace with a secure method for storing your API key
    const url = `https://api.hypixel.net/skyblock/bazaar?key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.success) {
            return data.products; // This contains the product data
        } else {
            throw new Error('Failed to fetch data from the Hypixel API');
        }
    } catch (error) {
        console.error('Error fetching Bazaar data:', error);
        return null;
    }
}